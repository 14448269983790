import React, { useEffect } from "react";
import { useToasts } from 'react-toast-notifications';
import "./styles.css";

function InitToast() {
  const { addToast } = useToasts();
  // react toastify
  useEffect(() => {
    addToast('Welcome to Valapad Football Club', { 
      appearance: 'success',
      placement: 'top-right',
      autoDismiss:false,
      autoDismissTimeout: 3000,
      newestOnTop: true,
    
  });

  }, []);



  return (
    
    <React.StrictMode>
      <div className="init-toast">
      </div>    
    </React.StrictMode>
  );
}

export default InitToast;
